<template>
  <div class="c">
    <p>一、活动时间：</p>
    <p>2022年12月1日上午10点——2022年12月31日上午10点</p>
    <p>二、活动内容：</p>
    <p>1.活动期间在
      <span style="color: rgb(227, 55, 55);"><b>该活动页面</b></span>
    购买永久会员，可购买单人永久会员和多人永久会员套餐，购买完成后会获得相应数量的激活码
    <b style="color: rgb(227, 55, 55);">1个激活码可以激活1个账号成为永久会员</b>，
    输入手机号即可激活。激活码可激活自己成为会员也可为他人激活成为会员。</p>
    <p>2.一个激活码只可激活一次，若因输错号码导致激活到错误手机号，一律不予更换，
      <b style="color: rgb(227, 55, 55);">请在激活号码时再三确认激活的手机号</b>
	<b style="color: rgb(227, 55, 55);">。</b>
</p>
<p>
	<span style="color: rgb(227, 55, 55);">
		<b>注：此活动不可和其他优惠同时参与，且激活码请在活动期间进行激活，超过时间未激活视为自动放弃。</b>
	</span>
</p>
<p>三、付费服务</p>
<p>1.你理解并同意，会员订阅服务为付费服务，有鱼记账将收取互联网增值服务使用费；
  你可以在相关服务页面查阅会员订阅服务具体期限及对应费用并自行选择服务的种类。开启会员订阅功能后，无论你是否在相应服务期限内实际使用该服务，已支付的费用不支持退款。</p>
<p>2.你理解并同意，我司有权自行决定并不时修订会员订阅服务相关的收费标准和规则，该类标准及规则一经发布即生效，并构成本条款的有效组成部分。如你不同意前述标准、规则及其修订，
  你有权停止使用会员订阅服务。你继续使用会员订阅服务即视为你同意相关内容。</p>
<p>四、免责声明</p>
<p>我司有权根据产品计划和运营情况独立决定会员订阅服务的具体内容，并有权根据情况新增、移除暂停或终止提供会员订阅服务的部分服务。前述决定不经通知即生效，你同意我司对此免责。</p>
<p>本活动最终解释权归有鱼记账所有，如有疑问可添加企业微信，联系有鱼客服进行咨询。</p>
<p>微信扫描下方二维码，即可添加客服</p>
<p>
	<img alt="Image" src="https://download-cos.yofish.com/ad-gongjushiyebu/20221122141650216-ewm.png"
		width="296" height="296">
</p>
  </div>
</template>
<script>
export default {
  name: 'PackageRule',
};
</script>
<style lang="scss" scoped>
  p {
    font-size: 26px;
    line-height: 42px;
  }
</style>
