<template>
  <div class="home-page" :style="style">
    <div class="loading" v-show="loading">{{ textcontent }}</div>
    <div class="rule-btn activity-btn" @click="ruleVisible = true">活动规则</div>
    <div class="code-btn activity-btn" @click="jumpAction">我的激活码</div>
    <div class="head">
      <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/20221122155321164-pack-head.png" alt="">
    </div>
    <div class="main img-common">
      <div class="vip-row">
        <div class="vip-modular">
          <div class="h-card img-common">
            <div class="label">永久会员·2人</div>
            <div class="price-row">
              <div class="price-con">
                <div class="price">¥398</div>
                <div class="price-desc">
                  <p>¥199/人</p>
                  <p class="undeline">原价:¥596</p>
                </div>
              </div>
              <div class="seckill-button" @click="payAction(398, 'vip_activate_2')">
              <img
              src="//download-cos.yofish.com/yofish-gongjushiyebu/20221123091517475-seckill-btn.png"
                alt="">
            </div>
            </div>
          </div>
        </div>
        <div class="vip-modular">
          <div class="s-card img-common">
            <div class="label">永久会员·1人</div>
            <div class="label-desc">¥298/人</div>
            <div class="price-value">¥298</div>
            <div class="open-btn" @click="payAction(298, 'vip_activate_1')">立即开通</div>
          </div>
          <div class="s-card img-common">
            <div class="label">永久会员·5人</div>
            <div class="label-desc">¥179/人</div>
            <div class="price-value">¥898</div>
            <p class="unlined-price">原价:¥1490</p>
            <div class="open-btn" @click="payAction(898, 'vip_activate_5')">立即开通</div>
          </div>
        </div>
        <div class="package-desc">购买完成后会获得对应数量的激活码。1个激活码可激活1个账号成为永久会员，输入手机号即可激活。</div>
        <div class="vip-info">
          <img
          src="//download-cos.yofish.com/yofish-gongjushiyebu/20221123110122100-vipinfo-bg.png"
          alt="">
        </div>
      </div>
    </div>
    <!----活动时间弹窗--->
    <y-dialog :visible.sync="dialogData.visible" :appendToBody="false"
      class="activity-dialog-main"
      :is-out-click="dialogData.type === 'wechart'">
      <div class="activity-dialog">
        <h1 class="title">{{ dialogData.title }}</h1>
        <p class="content">{{ dialogData.content }}</p>
      </div>
      <div class="dialog-btns" slot="footer">
        <div class="btn primary" @click="okAction">我知道了</div>
      </div>
    </y-dialog>
    <!-----支付成功弹窗------>
    <y-dialog  :visible.sync="paySuccessVisible" :appendToBody="false"
      class="pay-success-dialog">
      <div class="close-image" slot="header" @click="paySuccessVisible = false">
        <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/20221024140036315-close-btn.png" alt="">
      </div>
      <div class="content">
        <p class="title">支付成功</p>
        <p class="desc">激活码已发放</p>
        <p class="desc">可点击【我的激活码】去激活</p>
      </div>
      <div class="button" @click="jumpAction('modal')">
        立即前往
      </div>
    </y-dialog>
    <!----活动规则--->
    <y-actionsheet :is-visible="ruleVisible"
      :isLockBgScroll="true"
      @close="ruleVisible = false">
      <span slot="sTitle">活动规则</span>
      <div slot="custom">
        <rule />
      </div>
    </y-actionsheet>
  </div>
</template>
<script>
import { getAppStatus } from '@/utils/app';
import YDialog from '@/components/dialog';
import YActionsheet from '@/components/actionsheet';
import rule from './rule';
import {
  getBlindboxActivityInfoApi,
  getBuyTimeApi,
} from '@/api/base1';

export default {
  name: 'PackageHome',
  components: { YDialog, YActionsheet, rule },
  data() {
    return {
      paySuccessVisible: false,
      ruleVisible: false,
      loading: false,
      textcontent: '',
      style: {},
      dialogData: {
        title: '当前活动未开始！',
        content: '',
        visible: false,
        type: 'activityTime',
      },
    };
  },
  computed: {
    cuserId() {
      return getAppStatus().cuserId;
      // return 'a5f47fed-7ffe-40b2-95d2-f6a2e85b5424';
      // return '7fafe1a4-ec10-4a41-b33e-365845d41032';
    },
    toastStyle() {
      return {
        backgroundColor: '#18695c',
      };
    },
    activityId() {
      return 5;
    },
    businessType() { // 活动标识
      return 0;
    },
    disabledScroll() {
      return this.ruleVisible || this.dialogData.visible
      || this.paySuccessVisible;
    },
  },
  watch: {
    paySuccessVisible(val) {
      const orderId = sessionStorage.getItem('orderId11');
      if (!val && orderId) sessionStorage.removeItem('orderId11');
    },
  },
  created() {
    this.cuserId && this.blindMonitor();
  },
  mounted() {
    document.title = '呼朋唤友 共享狂欢';
    this.getOpenTimes();
    this.initData();
    this.$nextTick(() => {
      this.pageScroll();
    });
  },
  methods: {
    blindMonitor() {
      window._monitor('init', {
        businessType: this.businessType,
        businessId: this.activityId,
        cuserId: this.cuserId,
      });
      window._monitor('pageset', 'hytcym_imp', {
        eventName: '会员套餐页面曝光',
      });
    },
    async initData() {
      let res;
      this.loadingVisible = true;
      const { cuserId, activityId } = this;
      try {
        res = await getBlindboxActivityInfoApi({ cuserId, activityId });
      } catch (e) {
        console.log(e);
        this.loadingVisible = false;
        return this.$toast(e?.desc || '网络异常');
      }
      this.loadingVisible = false;
      this.$emit('loading-action');
      if (res?.code === 1) {
        const { startTime, endTime } = res?.results || {};
        this.pageStatus(startTime, endTime);
      }
    },
    pageStatus(startTime, endTime) {
      let currentTime = new Date().getTime();
      if (currentTime < startTime) {
        this.dialogData = {
          title: '当前活动未开始！',
          content: '',
          visible: true,
          type: 'activityTime',
        };
        return false;
      }
      if (currentTime > endTime) {
        this.dialogData = {
          title: '当前活动已结束！',
          content: '',
          visible: true,
          type: 'endTime',
        };
        return false;
      }
    },
    async getOpenTimes() {
      let res;
      const { cuserId, activityId } = this;
      const orderId = sessionStorage.getItem('orderId11');
      try {
        res = await getBuyTimeApi({ cuserId, activityId });
      } catch (e) {
        console.log(e);
      }
      if (res?.code === 1) {
        let newCount = res?.results || 0;
        let oldCount = localStorage.getItem('openCount');
        oldCount = Number(oldCount);
        console.log(newCount, oldCount);
        if (!orderId) {
          localStorage.setItem('openCount', newCount);
        }
        if (orderId && oldCount !== newCount) {
          localStorage.setItem('openCount', newCount);
          this.paySuccessVisible = true;
          this.paySuccessAction();
        }
      }
    },
    pageScroll() {
      let startPosition = 0;
      const page = document.querySelector('.home-page');
      let distance = 0;
      page.addEventListener('touchstart', (e) => {
        const top = 'scrollTop' in page ? page.scrollTop : 0;
        if (this.disabledScroll || top > 0) return false;
        this.textcontent = '下拉刷新';
        startPosition = e.touches[0].pageY;
      });
      page.addEventListener('touchmove', (e) => {
        const top = 'scrollTop' in page ? page.scrollTop : 0;
        if (this.disabledScroll) return false;
        let currentPosition = e.touches[0].pageY;
        distance = currentPosition - startPosition;
        if (distance <= 0 || top > 0) return false;
        if (distance > 80) {
          this.textcontent = '释放立即刷新';
          this.loading = true;
        }
        if (distance > 80 && distance < 150) {
          this.loading = true;
          this.style = {
            transition: 'transform .6s',
            transform: 'translateY(.5rem)',
          };
        }
      });
      page.addEventListener('touchend', (e) => {
        const top = 'scrollTop' in page ? page.scrollTop : 0;
        if (this.disabledScroll || top > 0) return false;
        if (distance > 0 && distance < 80) {
          this.style = {};
          this.loading = false;
          return false;
        }
        if (distance > 80) {
          this.style.transform = 'translateY(1rem)';
          this.textcontent = '正在刷新...';
          this.loading = true;
          this.getOpenTimes();
          console.log('刷新中');
          setTimeout(() => {
            this.style = {};
            this.loading = false;
            distance = 0;
          }, 400);
        }
      });
    },
    jumpAction() {
      this.paySuccessVisible = false;
      this.$router.push('/activity/package/activation');
    },
    payAction(price, goodsId) {
      let { origin } = window.location;
      let payMoney = origin.includes('jz-h5.yofish.com') ? price : 0.01;
      let params = {
        payMoney,
        payType: 0, // 0 支付宝 1：零钱
        smsCode: '', // 验证码 payType=1必传
        jumpUrl: origin + '/activity/package/home', // 跳转链接 payType=0必传
        scenes: 6, // 支付场景 0：皮肤 1：购买会员 2:导出数据 3：数据恢复 4：打赏 6: 拆盲盒
        goodsId, // 购买的商品id,(皮肤，会员必传)
        orderId: '', // 订单id ：scenes=2和3必传(需要先调用对应的生成订单接口)
        memo: '', // 备注 ：scenes=4必传
        activityId: this.activityId,
      };
      this.clickMonitorAction(goodsId);
      this.$router.push({
        path: '/payment',
        query: params,
      });
    },
    clickMonitorAction(goodsId) {
      let event = '';
      let value = '';
      if (goodsId === 'vip_activate_1') {
        event = 'hytcy_ljgman_click1';
        value = 1;
      }
      if (goodsId === 'vip_activate_2') {
        event = 'hytcy_ljmsan_click';
        value = 2;
      }
      if (goodsId === 'vip_activate_5') {
        event = 'hytcy_ljgman_click5';
        value = 5;
      }
      window?._monitor('track', event, {
        eventName: '立即秒杀按钮点击（永久会员' + value + '人）',
      });
    },
    paySuccessAction() {
      const orderId = sessionStorage.getItem('orderId11');
      if (orderId) {
        this.paySuccessVisible = true;
        this.payMonitorActio();
      }
    },
    payMonitorActio() {
      const goodsId = sessionStorage.getItem('goodsId');
      let event = '';
      let value = '';
      if (goodsId === 'vip_activate_1') {
        event = 'hytcy_zfcgtc_imp1';
        value = 1;
      }
      if (goodsId === 'vip_activate_2') {
        event = 'hytcy_zfcgtc_imp2';
        value = 2;
      }
      if (goodsId === 'vip_activate_5') {
        event = 'hytcy_zfcgtc_imp5';
        value = 5;
      }
      window?._monitor('pageset', event, {
        eventName: '支付成功弹窗曝光（永久会员·' + value + '人）',
      });
    },
    okAction() {
      this.dialogData.visible = false;
      const { type } = this.dialogData;
      window?.android?.jzClose();
      window?.ios?.jzClose();
    },
  },
};
</script>
<style lang="scss" scoped>
@import './styles/home.scss';
</style>
